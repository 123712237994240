body{
  margin: 0;
  padding: 0;
  font-family: $font-family;
  padding: 2rem 2rem 3rem;
  height: 100vh;
  a,
  button{
    transition: $transition;
  }
  .container {
    display: flex;
    flex-flow: row wrap;
    background: rgba(255,255,255,0.85);
    height: calc(100vh - 6rem);
    padding: 1rem;
    justify-content: space-around;
  }
}

button{
  background: $primary;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5rem;
  margin: 0.5rem 0.5rem;
  border-radius: 100px;
  border: none;
  color: white;
  &:hover{
    background: $secondary  ;
  }
}
.disabled{
  filter: grayscale(100%)!important;
  pointer-events: none!important;
  opacity: 0.5;
}