.prelolader{
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    height: 100vh;
    transition: opacity .5s ease-in-out;
    background: #222;
    &.dark{
        .wrap{
            .my-row{
                #white_logo{
                   
                }
            }
        }
    }
    .wrap{
        height: 100%;
        .my-row{
            height: 100%;
            align-items: center;
            display: flex;
            #white_logo{
                margin: auto;
                width: 30%;
                &.animate{
                    path{
                        fill: rgba(250, 250, 250, 1);
                        stroke:rgba(250, 250, 250, 1);
                        animation: preload-dark 3s ease-in-out;
                    }
                }
                path{
                    fill: rgba(250, 250, 250, 0);
                    stroke: rgba(250, 250, 250, 0);
                    stroke-dasharray: 1250;
                    stroke-dashoffset: 1250;
                    opacity: 1;
                }
            }
        }
    }
}


@keyframes preload-dark {
    0%{
        opacity: 0;
        fill: rgba(250, 250, 250, 0);        
        stroke-dashoffset: 2500;
    }
    50%{
        opacity: 1;
        fill: rgba(250, 250, 250, 0);
        stroke-dashoffset: 100;
    }
    90%{
        fill: #fff;
    }
    100%{
        opacity: 1;
        fill: #fff;
        stroke-dashoffset: 0;
    }
}