/*-----------------------------------------------------------------------------
    COLORS
-----------------------------------------------------------------------------*/

$orange:#dc8665;
$yellow:#eeb461;
$purple:#554667;
$green:#128084;
$peach:#ce7671;

$gray1:#FFFCFB;
$gray2:#757575;
$gray3:#231F26;
$gray4:#1D1A1F;
$gray5:#131313;

$black: #000;
$white: #fff;
$bg: $gray4;
$text: #fff;

$primary: $purple;
$secondary:$yellow;



$notification:#009DFF;
$success:#27D086;
$error:#FF5F5F;
$warning:#FF6C00;
$gradient: linear-gradient(120.39deg, $primary 12.04%, $gray4 81.28%);

$body-font:$gray5;
$body: $white;

$sidebar:$black;


$ru: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='flag-icon-css-ru' viewBox='0 0 640 480'%3E%3Cg fill-rule='evenodd' stroke-width='1pt'%3E%3Cpath fill='%23fff' d='M0 0h640v480H0z'/%3E%3Cpath fill='%230039a6' d='M0 160h640v320H0z'/%3E%3Cpath fill='%23d52b1e' d='M0 320h640v160H0z'/%3E%3C/g%3E%3C/svg%3E%0A");
$en: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='flag-icon-css-gb' viewBox='0 0 640 480'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill-opacity='.7' d='M-85.3 0h682.6v512H-85.3z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)' transform='translate(80) scale(.94)'%3E%3Cg stroke-width='1pt'%3E%3Cpath fill='%23012169' d='M-256 0H768v512H-256z'/%3E%3Cpath fill='%23fff' d='M-256 0v57.2L653.5 512H768v-57.2L-141.5 0H-256zM768 0v57.2L-141.5 512H-256v-57.2L653.5 0H768z'/%3E%3Cpath fill='%23fff' d='M170.7 0v512h170.6V0H170.7zM-256 170.7v170.6H768V170.7H-256z'/%3E%3Cpath fill='%23c8102e' d='M-256 204.8v102.4H768V204.8H-256zM204.8 0v512h102.4V0H204.8zM-256 512L85.3 341.3h76.4L-179.7 512H-256zm0-512L85.3 170.7H9L-256 38.2V0zm606.4 170.7L691.7 0H768L426.7 170.7h-76.3zM768 512L426.7 341.3H503l265 132.5V512z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
$et: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='flag-icon-css-ee' viewBox='0 0 640 480'%3E%3Cg fill-rule='evenodd' stroke-width='1pt'%3E%3Crect width='640' height='477.9' rx='0' ry='0'/%3E%3Crect width='640' height='159.3' y='320.7' fill='%23fff' rx='0' ry='0'/%3E%3Cpath fill='%231291ff' d='M0 0h640v159.3H0z'/%3E%3C/g%3E%3C/svg%3E%0A");
